<template>
  <v-row class="welcome d-flex justify-center align-start">
    <v-col
      cols="12"
      sm="10"
      md="10"
      lg="10"
      xl="10"
      class="leo d-flex flex-column align-start justify-start"
    >
      <BackButton />
      <v-col class="center-component" cols="12" align="center">
        <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="pa-0 ma-0">
          <v-img contain :src="leo" eager max-width="100" max-height="100">
          </v-img>
          <p
            class="text-h5 text-sm-h4 text-md-h4 text-lg-h4 text-xl-h4 primary--text mt-2"
          >
            {{ $t('forgot-password') }}
          </p>
          <v-col cols="8">
            <p class="text-body-1">
              {{ $t('forgot-password-info') }}
            </p>
          </v-col>
        </v-col>
        <v-form @submit.prevent="sendForgotPassword">
          <v-container class="justify-center align-center pa-0" align="center">
            <v-col
              cols="12"
              class="login-field justify-center align-center pa-0"
            >
              <v-col class="pb-0" cols="12" sm="8" md="8" lg="6" xl="6">
                <v-text-field
                  v-model="email"
                  :error-messages="emailErrors"
                  label="E-Mail"
                  id="email"
                  required
                  solo
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                  color="white"
                  class="student-login"
                ></v-text-field>
              </v-col>
            </v-col>
            <v-btn
              id="send-email"
              rounded
              color="#ead148"
              class="start-btn ma-1 mt-1 px-12 py-5"
              type="submit"
              :loading="isLoading"
            >
              <v-icon class="mr-2" color="#312570">mdi-email-fast</v-icon>
              <p
                class="ma-0 text-body-1 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 mt-1 myButton"
                v-html="$t('send-mail')"
              ></p>
            </v-btn>
          </v-container>
        </v-form>
      </v-col>
    </v-col>
    <LoginInfoBar />
  </v-row>
</template>

<script lang="ts">
import router from '@/router';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import LoginInfoBar from '../../base/LoginInfoBar.vue';
import BackButton from './BackButton.vue';

@Component({
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  components: { LoginInfoBar, BackButton },
})
export default class AdminLoginContainer extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  isDisabled = false;
  email = '';
  show = false;
  isLoading = false;

  get school(): string {
    // return this.userModule.currentUser.schoolName!;
    return 'VS Alphaport';
  }

  get leo(): string {
    return require('@/assets/images/lion_small.png');
  }

  async sendForgotPassword(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        this.isLoading = true;
        await this.userModule.forgotPassword(this.email);
        //push route according to role!
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.email.success').toString(),
          isSuccess: true,
        });
        setTimeout(async () => {
          router.push({
            name: 'school',
            replace: true,
            params: { schoolName: getSchoolPrefix() },
          });
        }, 5000);
      } catch (error) {
        // console.log(error);
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.email.error').toString(),
          isSuccess: false,
        });
      }
      this.isLoading = false;
    }
  }

  clear(): void {
    this.$v.$reset();
    this.email = '';
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push('Keine gültige E-Mailadresse.');
    !this.$v.email.required && errors.push('Bitte E-Mail angeben.');
    return errors;
  }
}
</script>

<style scoped lang="scss">
.welcome,
.main-row {
  width: 100%;
}

.leo {
  height: 50vh;
}

.start-btn {
  p {
    color: $dark-blue !important;
  }
}

.login-field .v-messages__message {
  color: white !important;
  caret-color: white !important;
}
</style>
