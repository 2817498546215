<template>
  <v-footer
    padless
    class="admin-footer mt-10"
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/bg_bottom.svg') + ')',
    }"
  >
    <v-col class="text-center" cols="12">
      <p class="links caption ma-0">
        <a @click.prevent="goToContact">Kontakt</a>
        |
        <a @click.prevent="goToLegal">{{ $t('imprint.title') }}</a>
        |
        <a @click.prevent="goToDataPolicy">{{ $t('data-policy') }}</a>
      </p>
    </v-col>
  </v-footer>
</template>

<script lang="ts">
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import router from '../../../router';

@Component
export default class AdminFooter extends Vue {
  goToContact(): void {
    router.push({
      name: 'imprint',
      hash: '#legal',
      params: { schoolName: getSchoolPrefix() },
    });
  }
  goToLegal(): void {
    router.push({
      name: 'imprint',
      hash: '#legal',
      params: { schoolName: getSchoolPrefix() },
    });
  }
  goToDataPolicy(): void {
    router.push({
      name: 'imprint',
      hash: '#data-policy',
      params: { schoolName: getSchoolPrefix() },
    });
  }
}
</script>

<style scoped lang="scss">
.admin-footer {
  background-position: bottom;
  width: 100%;

  * {
    color: $text-primary;
  }
  .links {
    a {
      text-decoration: none;
    }
  }
}
</style>
