<template>
  <v-btn text large @click="action" class="myButton backButton text-none">
    <v-icon large class="arrow" color="white" left>mdi-arrow-left</v-icon>
    <p
      class="pa-0 ma-0 text-h6 text-sm-h5 text-md-h5 text-lg-h5 text-xl-h5 norddruck white--text"
    >
      {{ title ? title : $t('back') }}
    </p>
  </v-btn>
</template>

<script lang="ts">
import router from '@/router';
import UserModule from '@/store/modules/UserModule';
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

@Component
export default class BackButton extends Vue {
  @Prop()
  title?: string;

  userModule: UserModule = getModule(UserModule, this.$store);

  get isAuth(): boolean {
    return this.userModule.currentUser._id !== '';
  }

  action(): void {
    if (!this.isAuth) {
      router.push({
        name: 'school',
        params: { schoolName: getSchoolPrefix() },
      });
    } else if (this.$route.name === 'imprint') {
      router.back();
    } else {
      router.push({
        name: 'school',
        params: { schoolName: getSchoolPrefix() },
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.backButton {
  position: absolute;
  top: 1%;
  left: 1%;
}
</style>
