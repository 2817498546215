<template>
  <v-app-bar
    flat
    class="admin-header d-flex flex-column justify-start py-0"
    height="auto"
  >
    <v-col class="pa-0" cols="12">
      <v-row class="admin-header__row d-flex justify-center align-center">
        <v-col cols="4" class="d-flex flex-column justify-center align-center">
          <!-- <v-img :src="logo" contain :aspect-ratio="16 / 9" width="90" eager /> -->
          <v-img :src="logo" width="180" max-width="180" eager />
        </v-col>

        <v-col
          cols="4"
          sm="4"
          md="4"
          lg="4"
          xl="4"
          class="admin-header__account d-flex justify-center align-center"
        >
          <p
            class="text-h6 text-sm-h5 text-md-h5 text-lg-h5 text-xl-h5 text-center ma-0 mt-2 primary--text unselectable font-weight-bold"
          >
            {{
              isTeacherView
                ? $t('teacher.grade') + ' ' + user.grade
                : $t('admin.headertitle')
            }}
          </p>
        </v-col>
        <v-col
          v-if="!isSmall && !isMobile"
          cols="2"
          class="admin-header__user d-flex flex-column justify-center align-end pt-2"
        >
          <p
            v-if="!isLoggedOut"
            class="caption text-end ma-0 admin-header__text pt-2 unselectable"
          >
            {{ $t('welcome') }},
          </p>
          <p class="caption text-end ma-0 admin-header__text unselectable">
            {{ user.firstname }} {{ user.lastname }}
          </p>
        </v-col>
        <v-col
          cols="4"
          sm="4"
          md="2"
          lg="2"
          xl="2"
          class="admin-header__logout d-flex flex-column justify-center align-center pt-3"
        >
          <v-btn id="logout-button" large depressed @click.native="logout">
            <v-col>
              <v-icon color="primary" dark> mdi-exit-to-app </v-icon>
              <p class="primary--text body-2 pa-0 ma-0">{{ $t('logout') }}</p>
            </v-col>
          </v-btn>
        </v-col>
      </v-row>
      <v-col class="colour__block ma-0 pa-0" cols="12">
        <v-col
          cols="12"
          class="colour__dark pa-0 py-4 d-flex justify-center align-center flex-wrap"
        >
          <HeaderDownloadButton
            :icon="'mdi-book-open-variant'"
            :name="$t('teacher.manual')"
            :link="'/schule/lehrer'"
            :disabled="isDemoVersion"
          />
          <HeaderDownloadButton
            :icon="'mdi-book-open-variant'"
            :name="$t('teacher.praxis')"
            :link="'/schule/lehrer'"
            :disabled="isDemoVersion"
          />
          <!--<HeaderInfoButton
            :icon="'mdi-frequently-asked-questions'"
            :name="$t('teacher.faq')"
            :link="'/schule/lehrer'"
          />
          <HeaderInfoButton
            :icon="'mdi-information-outline'"
            :name="$t('teacher.infos')"
            :link="'/schule/lehrer'"
          />-->
        </v-col>
        <v-col cols="12" class="colour__light pa-0 ma-0">
          <p class="blindtext transparent--text unselectable">t</p>
        </v-col>
      </v-col>
    </v-col>
  </v-app-bar>
</template>

<script lang="ts">
import HeaderDownloadButton from './HeaderDownloadButton.vue';
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import { getSchoolPrefix } from '@/utils/common';
import localforage from 'localforage';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { UserDto } from '../../../api/types';
import router from '../../../router';
import store from '../../../store';
import HeaderInfoButton from './HeaderInfoButton.vue';
import ClientModule from '@/store/modules/ClientModule';

@Component({ components: { HeaderInfoButton, HeaderDownloadButton } })
export default class AppHeader extends Vue {
  userModule: UserModule = getModule(UserModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  clientModule: ClientModule = getModule(ClientModule, this.$store);
  drawer = false;

  get user(): UserDto {
    return this.userModule.currentUser;
  }

  get isLoggedOut(): boolean {
    return this.user._id === '';
  }

  get logo(): string {
    return require('@/assets/images/leo_lesetest_logo.png');
    // return require('@/assets/images/logo.png');
  }

  get isLogin(): boolean {
    return (
      this.$route.name === 'school' ||
      this.$route.name === 'forgotPassword' ||
      this.$route.name === 'resetPassword'
    );
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get isSmall(): boolean {
    return this.$vuetify.breakpoint.smOnly;
  }

  get isTeacherView(): boolean {
    return this.$route.path.includes('students');
  }

  get isSmOrSmaller(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get isDemoVersion(): boolean {
    return this.schoolModule.isTutorialOnlyVersion;
  }

  async logout(): Promise<void> {
    const prefix = getSchoolPrefix();
    await localforage.clear();
    this.schoolModule.reset();
    this.clientModule.reset();
    this.userModule.logout();
    store.commit('logout');
    router.push({
      name: 'school',
      replace: true,
      params: { schoolName: prefix },
    });
    localStorage.clear();
  }
}
</script>

<style lang="scss">
$blockHeight: 120px;

.admin-header {
  width: 100%;
  &__text {
    color: $text-primary;
  }

  .v-toolbar__content {
    padding: 0 !important;
  }
}

.colour {
  &__dark {
    // height: $blockHeight;
    background-color: $primary;
  }

  &__light {
    // height: calc(#{$blockHeight} / 3);
    background-color: $secondary;
  }
}
.blindtext {
  margin: 0 !important;
}
</style>
