<template>
  <v-app ref="app" id="app" style="height: 100%; width: 100%" class="primary">
    <v-main>
      <router-view />
    </v-main>
    <click-audio />
    <!-- <BaseFooter class="base-footer-size" /> -->
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
// import BaseFooter from './components/base/BaseFooter.vue';
import ClickAudio from './components/base/ClickAudio.vue';

export default Vue.extend({
  components: { ClickAudio },
  name: 'App',

  data: () => ({}),
  mounted() {
    ((this.$refs.app as Vue).$el as HTMLElement).addEventListener(
      'touchend',
      (e) => {
        if (
          e &&
          e.target &&
          e.target instanceof HTMLElement &&
          !e.target.classList.contains('v-btn__content')
        ) {
          e.preventDefault();
          e.target.click();
        }
      },
    );
  },

  // destroyed() {},
});
</script>

<style lang="scss">
.v-app {
  font-family: $font-family, sans-serif !important;
}

body {
  touch-action: pan-x pan-y;
}
</style>
