<template>
  <v-col cols="6" class="d-flex-column align-center justify-center ma-auto">
    <ParentInfoRow :number="1" />
    <ParentInfoRow :number="2" />
    <ParentInfoRow :number="3" />
    <ParentInfoRow :number="4" />
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import ParentInfoRow from './ParentInfoRow.vue';

@Component({ components: { ParentInfoRow } })
export default class ParentInfoContainer extends Vue {}
</script>

<style scoped lang="scss"></style>
