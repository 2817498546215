import { render, staticRenderFns } from "./ParentsTable.vue?vue&type=template&id=47a950a2&scoped=true"
import script from "./ParentsTable.vue?vue&type=script&lang=ts"
export * from "./ParentsTable.vue?vue&type=script&lang=ts"
import style0 from "./ParentsTable.vue?vue&type=style&index=0&id=47a950a2&prod&lang=scss"
import style1 from "./ParentsTable.vue?vue&type=style&index=1&id=47a950a2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a950a2",
  null
  
)

export default component.exports