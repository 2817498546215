<template>
  <v-col cols="10" sm="10" md="10" lg="10" xl="9" class="mt-4 mb-12">
    <v-card class="parent-table mb-12">
      <v-card-title> </v-card-title>
      <v-data-table
        :headers="headers"
        :items="tests"
        sort-by="id"
        class="elevation-1"
        :locale="'de-GE'"
        hide-default-footer
        disable-pagination
      >
        <!-- CLASS -->
        <template v-if="isTherapist" v-slot:[`item.grade`]="{ item }">
          <td v-if="item.grade">{{ item.grade }}</td>
          <td v-else>-</td>
        </template>

        <!-- FORMS -->
        <template v-slot:[`item.test`]="{ item }">
          <v-row class="d-flex flex-column justify-center align-center">
            <v-btn
              color="primary"
              class="formbutton__form-a mb-3"
              :class="{
                'formbutton__form-a--chosen': isSelected(item, 'a'),
              }"
              @click="toggleForm('a', item)"
            >
              Form A
            </v-btn>

            <v-btn
              color="primary"
              class="formbutton__form-b"
              :class="{
                'formbutton__form-b--chosen': isSelected(item, 'b'),
              }"
              @click="toggleForm('b', item)"
            >
              Form B
            </v-btn>
          </v-row>
        </template>

        <!-- FINISHED -->
        <template v-slot:[`item.finished`]="{ item }">
          <v-row
            class="d-flex flex-column justify-center align-center done-row"
          >
            <v-icon v-if="item.doneA === 'true'" color="success" class="my-3">
              mdi-check
            </v-icon>
            <v-icon v-else color="blue-grey lighten-3" class="my-3">
              mdi-close
            </v-icon>

            <v-icon v-if="item.doneB === 'true'" color="success" class="my-3">
              mdi-check
            </v-icon>
            <v-icon v-else color="blue-grey lighten-3" class="my-3">
              mdi-close
            </v-icon>
          </v-row>
        </template>

        <!-- EVALUATION -->
        <template v-slot:[`item.evaluation`]="{ item }">
          <v-row
            class="d-flex flex-column justify-start align-center evaluation-flex"
          >
            <EvaluationButton
              v-if="item.doneA === 'true'"
              :student="item"
              :form="'a'"
            />
            <v-btn
              depressed
              color="transparent"
              v-else
              class="hidden-btn disabledButton"
            />
            <EvaluationButton
              v-if="item.doneB === 'true'"
              :student="item"
              :form="'b'"
            />
            <v-btn
              depressed
              color="transparent"
              v-else
              class="hidden-btn disabledButton"
            />
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>

<script lang="ts">
import EvaluationButton from '../students/table/EvaluationButton.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SchoolModule from '@/store/modules/SchoolModule';
import { getModule } from 'vuex-module-decorators';
import UserModule from '@/store/modules/UserModule';

@Component({
  components: { EvaluationButton },
})
export default class StudentsTable extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  @Prop() grade!: string;

  disableFormA: string[] = [];
  disableFormB: string[] = [];

  tests = [
    {
      id: 1,
      class: '',
      doneA: false,
      doneB: false,
    },
    {
      id: 2,
      class: '',
      doneA: false,
      doneB: false,
    },
  ];

  get headers(): any {
    return [
      { text: 'Test', value: 'studentId', align: 'center' },

      {
        text: 'Klasse',
        value: 'grade',
      },
      { text: 'Test', value: 'test', align: 'center', sortable: false },
      {
        text: 'Fertig',
        value: 'finished',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Auswertung',
        value: 'evaluation',
        sortable: false,
      },
    ];
  }

  get schoolSuffix(): string {
    return this.schoolModule.schoolSuffix;
  }

  // async save(user: UpdateUserDto): Promise<void> {
  //   try {
  //     await this.schoolModule.updateUser({
  //       id: this.editedItem._id,
  //       updateUser: user,
  //     });
  //     if (this.editedIndex > -1) {
  //       Object.assign(this.users[this.editedIndex], this.editedItem);
  //     } else {
  //       console.log('EDITED USERS: ', this.editedItem);
  //     }
  //     this.$emit('snackbar', {
  //       message: 'Schüler/in erfolgreich geändert!',
  //       isSuccess: true,
  //     });
  //   } catch (error) {
  //     this.$emit('snackbar', {
  //       message: 'Schüler/in konnte nicht geändert werden!',
  //       isSuccess: false,
  //     });
  //   }
  //   this.close();
  // }
}
</script>

<style lang="scss">
.parent-table {
  .v-data-table {
    line-height: 10;

    &__wrapper tr:nth-of-type(even) {
      background-color: $row-color;
    }
  }

  &__action-item {
    height: 25% !important;
  }

  .formbutton {
    color: white !important;

    &__form-a {
      background-color: $button-form-a-active !important;

      &--chosen {
        background-color: $button-form-a-disabled !important;
        color: $text-primary !important;
      }
    }
    &__form-b {
      background-color: $button-form-b-active !important;

      &--chosen {
        background-color: $button-form-b-disabled !important;
        color: $text-primary !important;
      }
    }
  }
}

.evaluation-flex {
  gap: 12px;
}

.hidden-btn {
  display: hidden;
  background-color: transparent !important;
}
</style>

<style lang="scss" scoped>
.action-row {
  width: 60%;
}

.done-row {
  height: 100%;
}
</style>
