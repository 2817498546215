<template>
  <v-col cols="12" class="d-flex justify-start align-center parent-info ma-2">
    <p
      class="number primary--text text-h5 text-sm-h3 text-md-h3 text-lg-h3 text-xl-h3 font-weight-bold ma-3"
    >
      {{ number }}
    </p>
    <v-row class="parent-info__row" v-if="number === 1">
      <p class="description caption ma-0">Wählen Sie eine Klasse aus.</p>
    </v-row>
    <v-row class="parent-info__row" v-if="number === 2">
      <p class="description caption ma-0">
        Wählen Sie eine Test-Form aus. Wenn Ihr Kind zum allerersten Mal einen
        Test macht, wählen Sie <strong>Form A.</strong>
      </p>
      <p class="description caption ma-0">
        Wenn Sie mit dem gleichen Kind einen Wiederholungstets nach einigen
        Monaten machen möchten, wählen Sie <strong>Form B.</strong>
      </p>
    </v-row>
    <v-row class="parent-info__row" v-if="number === 3">
      <p class="description caption ma-0">
        Wenn alle Aufgaben des Tests vollständig bearbeitet wurden, erscheint
        ein <v-icon color="green">mdi-check</v-icon>.
        <br />

        Jetzt können Sie den Test auswerten.
      </p>
    </v-row>
    <v-row class="parent-info__row" v-if="number === 4">
      <p class="description caption ma-0">
        Achtung: Wir wollen die Test-Ergebnisse so kurz wie möglich speichern.
        Sie haben nach der Testdurchführung einen Monat Zeit, den Test
        auszudrucken. Einloggen können Sie sich jederzeit, um das Praxis-Buch zu
        lesen.
      </p>
      <p class="description caption ma-0">
        Jetzt können Sie den Test auswerten.
      </p>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ParentInfoRow extends Vue {
  @Prop() number!: number;
}
</script>

<style scoped lang="scss">
.number {
  color: $primary;
}

.description {
  color: $text-primary;
}

.parent-info {
  background-color: $box-bg;

  &__row {
    margin: 0 !important;
  }
}
</style>
