<template>
  <v-row class="welcome d-flex justify-center align-center">
    <v-col
      cols="12"
      sm="10"
      md="10"
      lg="10"
      xl="10"
      class="leo d-flex flex-column align-center justify-center"
    >
      <v-col cols="12" align="center">
        <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="pa-0">
          <a @click.prevent="goHome">
            <v-img contain :src="leo" eager max-width="100" max-height="100">
            </v-img
          ></a>
          <p
            class="text-h5 text-sm-h4 text-md-h4 text-lg-h4 text-xl-h4 primary--text mt-2"
          >
            {{ school }}
          </p>
        </v-col>
        <v-form @submit.prevent="login">
          <v-container class="justify-center align-center pa-0" align="center">
            <v-col
              cols="12"
              class="login-field justify-center align-center pa-0"
            >
              <v-col class="pb-0" cols="12" sm="8" md="8" lg="6" xl="5">
                <v-text-field
                  v-model="username"
                  :error-messages="usernameErrors"
                  label="E-Mail"
                  id="username"
                  required
                  solo
                  @input="$v.username.$touch()"
                  @blur="$v.username.$touch()"
                  color="white"
                  class="student-login"
                ></v-text-field>
              </v-col>

              <v-col class="pb-0" cols="12" sm="8" md="8" lg="6" xl="5">
                <v-text-field
                  v-model="password"
                  :error-messages="passwordErrors"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  label="Passwort"
                  id="password"
                  required
                  solo
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                  color="grey--light"
                  class="student-login"
                ></v-text-field>
              </v-col>
            </v-col>
            <v-btn
              rounded
              color="#ead148"
              id="login-btn"
              class="start-btn"
              :loading="loading"
              :disabled="licenseExpired"
              type="submit"
            >
              <p
                class="ma-0 text-h6 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 myButton"
              >
                {{ licenseExpired ? $t('license-expired') : $t('login') }}
              </p>
            </v-btn>
          </v-container>
          <v-btn id="forgot-password-button" @click="goToForgotPassword" text>
            {{ $t('forgot-password') }}</v-btn
          >
        </v-form>
      </v-col>
    </v-col>
    <LoginInfoBar />
    <LicenseWarning />
  </v-row>
</template>

<script lang="ts">
import LicenseWarning from './LicenseWarning.vue';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import { Role } from '../../../api/types';
import LoginInfoBar from '../../base/LoginInfoBar.vue';

@Component({
  mixins: [validationMixin],
  validations: {
    username: { required, minLength: minLength(3) },
    password: { required, minLength: minLength(3) },
  },
  components: { LoginInfoBar, LicenseWarning },
})
export default class AdminLoginContainer extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  isDisabled = false;
  username = '';
  password = '';
  show = false;
  loading = false;

  get leo(): string {
    return require('@/assets/images/lion_small.png');
  }

  get school(): string {
    return this.schoolModule.schoolName;
  }

  get licenseExpired(): boolean {
    return this.schoolModule.isLicenseTwoWeeksExpired;
  }
  get schoolId(): string {
    return this.schoolModule.schoolId ?? '';
  }

  goHome(): void {
    this.$router.push({
      name: 'login',
      replace: true,
      params: { schoolName: getSchoolPrefix() },
    });
  }

  goToForgotPassword(): void {
    this.$router.push({
      name: 'forgotPassword',
      replace: true,
      params: { schoolName: getSchoolPrefix() },
    });
  }

  async login(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        await this.userModule.login({
          username: this.username.trim(),
          password: this.password.trim(),
        });
        await this.userModule.whoAmI(this.schoolId);
        const prefix = getSchoolPrefix();

        // INFO check if member in school group
        const schoolGroup = `/${this.schoolModule.schoolId ?? ''}`;
        let hasSchoolMatch = false;

        this.userModule.currentUser.groups?.forEach((group) => {
          if (group.length === schoolGroup.length && group === schoolGroup) {
            hasSchoolMatch = true;
          }
        });

        if (!hasSchoolMatch) {
          this.userModule.logout();
          throw new Error('No school match');
        }

        const role = this.userModule.currentUser.role;
        const hasAdminRights = this.schoolModule.adminIds?.includes(
          this.userModule.currentUser._id ?? '',
        );

        // todo if first login update user as activated!!!
        if (this.userModule.user.firstLogin) {
          await this.userModule.updateUser({
            data: { firstLogin: false },
            id: this.userModule.user._id,
          });
        }

        if (role === Role.superadmin) {
          this.$router.push({
            name: 'groups',
            replace: true,
            params: { schoolName: prefix },
          });
        } //INFO if admin with admin rights go to teachers table
        else if (role === Role.admin && hasAdminRights) {
          this.$router.push({
            name: 'teachers',
            replace: true,
            params: { schoolName: prefix },
          });
        }
        // INFO admin without admin rights same as teacher
        else if (role === Role.admin && !hasAdminRights) {
          this.$router.push({
            name: 'students',
            replace: true,
            params: { schoolName: prefix },
          });
        } else if (
          role === Role.teacher ||
          role === Role.therapist ||
          role === Role.parent
        ) {
          this.$router.push({
            name: 'students',
            replace: true,
            params: { schoolName: prefix },
          });
        } else {
          this.$router.push({
            name: 'school',
            replace: true,
            params: { schoolName: prefix },
          });
        }
      } catch (error: any) {
        if (error?.message === 'No school match') {
          this.snackbarModule.showMessage({
            message: 'Benutzerdaten falsch!',
            isSuccess: false,
          });
        } else if (
          error?.response?.data?.message.includes(
            'User is not member of school group',
          )
        ) {
          this.snackbarModule.showMessage({
            message: 'Benutzerdaten falsch!',
            isSuccess: false,
          });
        } else if (error.response?.data?.statusCode === 400) {
          this.snackbarModule.showMessage({
            message: 'Fehler: Versuchen Sie es später erneut!',
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: 'Email oder Passwort falsch!',
            isSuccess: false,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }

  clear(): void {
    this.$v.$reset();
    this.username = '';
    this.password = '';
  }

  get usernameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.username.$dirty) return errors;
    !this.$v.username.minLength &&
      errors.push('Dein Benutzername ist mind. 3 Buchstaben lang!');
    !this.$v.username.required && errors.push('Wie lautet dein Benutzername?');
    return errors;
  }

  get passwordErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.password.$dirty) return errors;
    !this.$v.password.minLength &&
      errors.push('Dein Passwort hat mind. 3 Buchstaben!');
    !this.$v.password.required && errors.push('Bitte gib dein Passwort ein!');
    return errors;
  }
}
</script>

<style scoped lang="scss">
.welcome,
.main-row {
  width: 100%;
}

.start-btn {
  width: 45%;
  height: 32px !important;

  p {
    color: $dark-blue !important;
  }
}

.login-field .v-messages__message {
  color: white !important;
  caret-color: white !important;
}

@media screen and (min-width: 600px) {
  .start-btn {
    width: 35%;
    height: 32px !important;
  }
}

@media screen and (min-width: 960px) {
  .start-btn {
    width: 25%;
    height: 32px !important;
  }
}
@media screen and (min-width: 1264px) {
  .start-btn {
    width: 25%;
    height: 40px !important;
  }
}
</style>
