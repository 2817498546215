<template>
  <!-- :timeout="2000" -->
  <v-snackbar :value="snackbar" :color="color" class="admin-snackbar">
    <v-row
      id="admin-snackbar-message"
      class="d-flex justify-center align-center"
    >
      <v-icon v-if="isSuccess" left dark>mdi-check-circle </v-icon>
      <v-icon v-else left dark>mdi-alert-circle </v-icon>
      <p class="ma-0 mt-1">{{ message }}</p>
    </v-row>
  </v-snackbar>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import SnackbarModule from '../../../store/modules/SnackbarModule';

@Component({
  components: {},
})
export default class TeacherSnachbar extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  get text(): string {
    return this.isSuccess ? this.message : 'Leider ist etwas schiefgelaufen.';
  }

  get snackbar(): boolean {
    return this.snackbarModule.showSnackbar;
  }

  get message(): string {
    return this.snackbarModule.message;
  }

  get timeout(): number {
    return this.snackbarModule.timeout;
  }

  get color(): string {
    return this.isSuccess ? 'success' : 'error';
  }

  get isSuccess(): boolean {
    return this.snackbarModule.isSuccess;
  }
}
</script>

<style scoped lang="scss">
// .admin-snackbar {
//   position: absolute;
//   bottom: 0;
// }
</style>
