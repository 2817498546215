<template>
  <v-col
    cols="12"
    class="parent-dashboard d-flex-column align-center justify-start"
  >
    <ParentInfoContainer />
    <ParentInfoContainer />
    <!-- <ParentsTable /> -->
    <AdminSnackbar
      :snackbar="snackbar"
      :isSuccess="isSuccess"
      :message="message"
    />
  </v-col>
</template>

<script lang="ts">
import ParentsTable from './ParentsTable.vue';
import ParentInfoContainer from './info/ParentInfoContainer.vue';
import AdminSnackbar from '../base/AdminSnackbar.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Role, UserDto } from '../../../api/types/api';
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import { getModule } from 'vuex-module-decorators';
import { SnackbarData } from '../../../types';

@Component({
  components: {
    AdminSnackbar,
    ParentInfoContainer,
    ParentsTable,
  },
})
export default class ParentDashboard extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  students: UserDto[] = [];
  snackbar = false;
  isSuccess = false;
  message = '';

  get teacher(): UserDto {
    return this.userModule.currentUser;
  }

  get grade(): string {
    return this.teacher.grade!;
  }

  get myStudents(): UserDto[] {
    return this.students;
  }

  get isTherapist(): boolean {
    return this.userModule.currentUser.role === Role.therapist;
  }

  // async created(): Promise<void> {
  //   await this.fetchStudents();
  // }

  async fetchStudents(): Promise<void> {
    this.students = this.isTherapist
      ? await this.schoolModule.getPatients()
      : await this.schoolModule.getStudents(this.teacher.grade!);
  }

  showSnackbar(data: SnackbarData) {
    this.snackbar = true;
    this.isSuccess = data.isSuccess;
    this.message = data.message;
    setTimeout(async () => {
      this.snackbar = false;
    }, 2500);
  }
}
</script>

<style scoped lang="scss"></style>
