<template>
  <v-btn
    class="helper-button mx-1 my-1"
    height="80"
    width="110"
    elevation="2"
    @click="goToHelperSite"
  >
    <v-col class="ma-0 pa-0">
      <v-icon color="primary" dark class="mr-1"> {{ icon }} </v-icon>
      <p class="primary--text pa-0 ma-0 mt-1 text-body-1 text-decoration-none">
        {{ name }}
      </p>
    </v-col>
  </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class HeaderInfoButton extends Vue {
  @Prop() link!: string;
  @Prop() name!: string;
  @Prop() icon!: string;

  goToHelperSite(): void {
    console.log('GO TO infos!');
  }
}
</script>

<style scoped lang="scss">
.helper-button {
  text-transform: none;
}
</style>
