<template>
  <v-row class="pa-0">
    <BackButton :title="$t('to-login')" />
    <v-col class="reset-password pa-0" justify="center" cols="12">
      <v-row class="d-flex justify-center align-center">
        <v-form @submit.prevent="setPassword">
          <v-container class="justify-center align-center pa-0" align="center">
            <v-col
              cols="12"
              class="pa-0 ma-0 d-flex justify-center align-center"
            >
              <v-img
                contain
                :src="leo"
                eager
                max-width="100"
                max-height="100"
              />
            </v-col>
            <v-col>
              <p
                class="text-h5 text-sm-h4 text-md-h4 text-lg-h4 text-xl-h4 primary--text text-center mt-2"
              >
                {{ $t('change-password') }}
              </p>
              <p class="text-body-1">
                {{ $t('change-password-info') }}
                <span
                  ><a :href="forgotPasswordLink">{{ $t('here') }}</a></span
                >
              </p>
            </v-col>
            <v-col class="py-0" cols="12">
              <v-text-field
                v-model="email"
                :error-messages="emailErrors"
                label="E-Mail"
                required
                solo
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
                color="white"
                class="student-login"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12">
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :error-messages="passwordErrors"
                :label="$t('new-password')"
                name="password"
                color="primary"
                solo
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12">
              <v-text-field
                v-model="confirmPassword"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                :error-messages="confirmPasswordErrors"
                :label="$t('confirm-password')"
                name="new-password"
                color="primary"
                solo
                @input="$v.confirmPassword.$touch()"
                @blur="$v.confirmPassword.$touch()"
                @click:append="showConfirmPassword = !showConfirmPassword"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex justify-center align-center ma-0 pa-0">
              <v-btn
                rounded
                color="#ead148"
                class="start-btn ma-1 mt-1 px-12 py-5"
                type="submit"
                :loading="loading"
              >
                <v-icon class="mr-2" color="#312570">mdi-lock-reset</v-icon>
                <p
                  class="ma-0 text-body-1 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 mt-1 myButton"
                  v-html="$t('confirm-password')"
                ></p>
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-center align-center">
              <v-btn text @click="goToLogin"
                ><v-icon x-small>mdi-arrow-right</v-icon>
                <p class="pa-0 ma-0 mt-1">{{ $t('to-login') }}</p></v-btn
              >
            </v-col>
          </v-container>
        </v-form>
      </v-row>
      <LoginInfoBar />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { email, minLength, required, sameAs } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import LoginInfoBar from '../../base/LoginInfoBar.vue';
import BackButton from './BackButton.vue';

@Component({
  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    confirmPassword: {
      required,
      minLength: minLength(8),
      sameAsPassword: sameAs('password'),
    },
  },
  components: { LoginInfoBar, BackButton },
})
export default class ResetPasswordForm extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  showPassword = false;
  showConfirmPassword = false;

  email = '';
  password = '';
  confirmPassword = '';
  token = '';

  loading = false;

  get leo(): string {
    return require('@/assets/images/lion_small.png');
  }

  mounted(): void {
    this.token = this.$route.query.token as string;
  }

  get forgotPasswordLink(): string {
    return `/#/${getSchoolPrefix()}/schule/forgot-password`;
  }

  async setPassword(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        await this.userModule.resetPassword({
          email: this.email,
          password: this.password,
          token: this.token,
        });
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.password.success').toString(),
          isSuccess: true,
        });
      } catch (error: any) {
        if (error?.response?.status === 400) {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('snackbar.password.invalid').toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('snackbar.password.error').toString(),
            isSuccess: false,
          });
        }
      }
      this.loading = false;
    }
  }

  goToLogin(): void {
    this.$router.push({
      name: 'school',
      replace: true,
      params: { schoolName: getSchoolPrefix() },
    });
  }

  clear(): void {
    this.$v.$reset();
    this.email = '';
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push('Keine gültige E-Mailadresse.');
    !this.$v.email.required && errors.push('Bitte E-Mail angeben.');
    return errors;
  }

  get passwordErrors(): string[] {
    const passwordLength = this.$i18n.t('snackbar.error.passwordLength');
    const requiredMessage = this.$i18n.t('snackbar.error.password');
    const errors: string[] = [];
    if (!this.$v.password.$dirty) return errors;
    !this.$v.password.minLength && errors.push(passwordLength.toString());
    !this.$v.password.required && errors.push(requiredMessage.toString());
    return errors;
  }

  get confirmPasswordErrors(): string[] {
    const invalidPassword = this.$i18n.t('snackbar.error.passwordLength');
    const sameAs = this.$i18n.t('snackbar.error.sameAs');
    const requiredMessage = this.$i18n.t('snackbar.error.password');
    const errors: string[] = [];
    if (!this.$v.confirmPassword.$dirty) return errors;
    !this.$v.confirmPassword.minLength &&
      errors.push(invalidPassword.toString());
    !this.$v.confirmPassword.sameAsPassword && errors.push(sameAs.toString());
    !this.$v.confirmPassword.required &&
      errors.push(requiredMessage.toString());
    return errors;
  }
}
</script>

<style scoped lang="scss">
.reset-password {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
