<template>
  <v-snackbar :value="showWarning" top right timeout="300000">
    <v-row class="license__content ma-0 pa-0 d-flex flex-column">
      <v-col class="d-flex justify-center align-center">
        <v-icon class="mr-2" color="warning">mdi-alert</v-icon>
        <p class="ma-0 text-h6">{{ $t('attention') }}</p>
        <v-icon class="ml-2" color="warning">mdi-alert</v-icon>
      </v-col>
      <v-col class="">
        <template v-if="isWarningExpiration">
          <p class="body-1 text-center">
            {{
              validDays > 0
                ? $t('license.warning', { days: validDays })
                : $t('license.expired')
            }}
          </p>
          <p
            class="license__extend body-1 text-center"
            v-html="$t('license.extend', { url: url })"
          ></p>
        </template>
        <template v-if="hasNoValidTestsLeft">
          <p class="body-1 text-center">
            {{ $t('license.no-valid-tests') }}
          </p>
          <p
            class="license__extend body-1 text-center"
            v-html="$t('license.test-extend', { url: url })"
          ></p>
        </template>
      </v-col>
      <v-col class="d-flex justify-end align-center pa-0">
        <v-btn text @click="closeWarning">
          {{ $t('close') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script lang="ts">
import { Client } from '@/api/types';
import SchoolModule from '@/store/modules/SchoolModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

@Component
export default class LicenseWarning extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);

  showWarning = false;

  get validDays(): number {
    return this.schoolModule.validDaysLeft;
  }

  get isSchool(): boolean {
    return this.schoolModule.clientType === Client.school;
  }

  get isTherapy(): boolean {
    return this.schoolModule.clientType === Client.therapy;
  }

  get isParent(): boolean {
    return this.schoolModule.clientType === Client.parent;
  }

  get isWarningExpiration(): boolean {
    return this.validDays < 10;
  }

  get hasNoValidTestsLeft(): boolean {
    return this.isTherapy ? this.schoolModule.hasNoValidTestsLeft : false;
  }

  get url(): string {
    return this.isSchool
      ? (this.$i18n.t('license.url.school') as string)
      : this.isTherapy
      ? (this.$i18n.t('license.url.therapy') as string)
      : (this.$i18n.t('license.url.parent') as string);
  }

  closeWarning(): void {
    this.showWarning = false;
  }

  @Watch('validDays', { immediate: true })
  onValidDaysChange(): void {
    if (this.validDays < 10 || this.hasNoValidTestsLeft) {
      this.showWarning = true;
    } else {
      this.showWarning = false;
    }
  }
}
</script>

<style lang="scss" scoped></style>
